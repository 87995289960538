import Axios from "axios";

const baseURL = "https://apidev.estudioproyector.net/api/v1";

const sxNetCalaApi = Axios.create({
    baseURL: baseURL, headers: {
        "Accept": "*/*",
        "Content-Type": "application/json"
    }
});

export default sxNetCalaApi;