import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';

export const DashBoardFilter = () => {
  const [t] = useTranslation("global");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClickOpenPopOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Box>
      <Typography
        sx={{
          color: "black",
          display: "flex",
          cursor: "pointer",
          padding: 1,
          borderRadius: 1,
          ":hover": {
            backgroundColor: '#e0e0e0',
          }
        }}
        onClick={handleClickOpenPopOver}
      >
        <Typography>{t("dashboard.filter")}</Typography>
        <FilterAltIcon />
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 500 }} padding={1}>
          <TextField
            placeholder={t("generic.search")}
            variant='outlined'
            size='small'
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Popover>
    </Box>
  )
}