import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";
import GenericPromises from "../../../api/GenericPromises";
import RandomCubesBackgroundWrapper from "../../../theme/projects/DashBoard/RandomCubesBackgroundWrapper";
import { HeaderDashBoard } from "../components/HeaderDashBoard";
import { ProjectType } from "../../../interfaces/Projects/Catalogs/projectTypes";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { ProjectStage } from "../../../interfaces/Projects/Catalogs/projectStages";
import { Project } from "../../../interfaces/Projects/projects";
import { Box, Typography } from "@mui/material";
import { useColors } from '../../../theme/hooks/useColors';
import { defaultTheme } from "../../../theme/theme";
import { CardStage } from "../../../theme/projects/DashBoard/CardStage";
import { useNavigate } from "react-router-dom";

export const DashBoard = () => {
  const [t] = useTranslation("global");
  const { GenericGetResourceGeneric, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { HexToRGB } = useColors();
  const navigate = useNavigate();
  const [comboProjectTypes, setComboProjectTypes] = useState<ProjectType[]>([]);
  const [defaultProjectType, setDefaultProjectType] = useState<ProjectType>();
  const [comboProjectStages, setComboProjectStages] = useState<ProjectStage[]>();
  /* TODO: type project dashboard with information of Store Procedure */
  const [projectsData, setProjectsData] = useState<any[]>();
  const [groupedProjects, setGroupedProjects] = useState<GroupedProjects>([]);
  const [color, setColor] = useState<number[]>();

  type GroupedProjects = {
    [stage_id: number]: Project[];
  };

  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("home.title")}`;
    });

    GenericGetResource(`/projecttypes`)
      .then((responseProjectTypes) => {
        let myComboProjectTypes: ProjectType[] = responseProjectTypes.data.data;
        setComboProjectTypes(myComboProjectTypes);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    setColor(HexToRGB(defaultTheme.palette.primary.main));

  }, []);

  useEffect(() => {
    if (!defaultProjectType && comboProjectTypes.length > 0) {
      const foundDefault = comboProjectTypes.find((item) => item.default_show_dashboard === true);
      setDefaultProjectType(foundDefault ?? comboProjectTypes[0]);
    }
  }, [comboProjectTypes]);

  useEffect(() => {
    if (defaultProjectType) {

      GenericGetResource(`/dashboard/currentstage/byprojecttypeid/${defaultProjectType.project_type_id}`)
        .then(async (responseProjectsData) => {
          if (responseProjectsData.data.data.length > 0) {
            await GenericGetResource(`/projectstages/byprojectid/${responseProjectsData.data.data[0].project_id}`)
              .then((responseStages) => {
                setComboProjectStages(responseStages.data.data);
              });
          }
          else {
            // TODO tarjeta de no hay datos por mostrar
          }
          setProjectsData(responseProjectsData.data.data);

        })
        .catch((error) => { showSnackBar(error.message, 'error'); });

    }
  }, [defaultProjectType]);

  useEffect(() => {
    if (comboProjectStages && projectsData) {
      const grouped = projectsData.reduce((acc: GroupedProjects, project: any) => {
        if (project.stage_id !== undefined) {
          if (!acc[project.stage_id]) {
            acc[project.stage_id] = [];
          }
          acc[project.stage_id].push(project);
        }
        return acc;
      }, {} as GroupedProjects);
      setGroupedProjects(grouped);
    }
  }, [comboProjectStages, projectsData]);

  return (
    <>
      <RandomCubesBackgroundWrapper>
        <HeaderDashBoard
          title={t("dashboard.title")}
          comboProjectTypes={comboProjectTypes}
          defaultProjectType={defaultProjectType}
          setDefaultProjectType={setDefaultProjectType}
        />

        <Box
          sx={{
            height: '100vh',
            overflowX: 'auto',
            display: 'flex',
          }}
        >
          {comboProjectStages && comboProjectStages.length > 0 && comboProjectStages.map((stageLocal, index) => (
            stageLocal.stage_id !== undefined ? (
              <Box
                key={index}
                sx={{
                  minWidth: 300,
                  borderRadius: 2,
                  margin: 1,
                  display: "flex",
                  flexDirection: 'column',
                  maxHeight: '80vh',
                  overflowY: 'auto',
                  scrollbarWidth: 1
                }}
              >
                <Box
                  sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    minHeight: 80,
                  }}
                >
                  <CardStage onClick={() => { }} color={color}>
                    <Typography fontWeight={"bold"}>
                      {stageLocal.project_stage_description} {`(${groupedProjects[stageLocal.stage_id]?.length ?? 0})`}
                    </Typography>
                  </CardStage>
                </Box>

                {groupedProjects[stageLocal.stage_id]?.map((project) => (
                  <Box
                    key={project.project_id}
                    sx={{
                      padding: 1,
                      marginBlock: 1,
                      border: '2px solid #ddd',
                      borderRadius: 1,
                      backgroundColor: 'whitesmoke',
                      cursor: 'pointer',
                      boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={()=>{navigate("/projectsdetails/view", { state: { row: { project_id: project.project_id } } });}}
                  >
                    <Typography>{project.project_name}</Typography>
                  </Box>
                ))}
              </Box>
            ) : null
          ))}

        </Box>
      </RandomCubesBackgroundWrapper>
      <SnackbarComponent />
    </>
  )
}
