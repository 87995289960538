import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ProjectTask } from "../../../../../../interfaces/Projects/Catalogs/projectTask";
import GenericPromises from "../../../../../../api/GenericPromises";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { useTranslation } from "react-i18next";
import { TaskState } from "../../../../../../interfaces/Projects/Catalogs/taskStates";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";

interface TaskItemProps {
  task: any;
  color: number[] | undefined;
  setSelectedTaskId: (id: number) => void;
  setOpenDialogUpdateTask: (open: boolean) => void;
}

export const TaskItem = ({
  task,
  color,
  setSelectedTaskId,
  setOpenDialogUpdateTask,
}: TaskItemProps) => {
  const [t] = useTranslation("global");
  const [onChecked, setOnChecked] = useState(false);
  const [loadingPut, setLoadingPut] = useState(false);
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GenericPutResource, GenericGetResource } = GenericPromises();
  const [taskStates, setTaskStates] = useState<TaskState[]>([]);
  const [projectTask, setProjectTask] = useState<ProjectTask>();
  const [dataloaded, setDataLoaded] = useState(false);

  const onPut = (currentTaskStateId: number) => {
    const myData = {
      // project_stage_id: task?.project_stage_id,
      // project_group_id: projectTask?.project_group_id,
      current_task_state_id: currentTaskStateId,
      // MS_task_id: projectTask?.MS_task_id ?? null,
      // MS_due_date_time: projectTask?.MS_due_date_time ?? null,
      // MS_start_date_time: projectTask?.MS_start_date_time ?? null,
      // MS_priority: projectTask?.MS_priority ?? null,
      // MS_applied_categories: projectTask?.MS_applied_categories ?? null,
      // MS_description: projectTask?.MS_description ?? null,
      // MS_show_in_planner: projectTask?.MS_show_in_planner ?? null,
      // project_task_name: task.project_task_name,
      // project_task_description: projectTask?.project_task_description ?? null,
      // project_task_started_at: projectTask?.project_task_started_at ?? null,
      // project_task_due_date:projectTask?.project_task_due_date ?? null,
      // project_task_notes: projectTask?.project_task_notes ?? null,
      // field_type_id:projectTask?.field_type_id
    };
    setLoadingPut(true);
    GenericPutResource(`/projecttask/${task.project_task_id}`, myData)
      .then((response) => {
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPut(false);
      })
      .catch((error) => {
        setOnChecked((prevChecked) => !prevChecked);
        setLoadingPut(false);
        showSnackBar(t(error.response.data), "error");
        return;
      });
  };

  const handleTaskCompletion = () => {
    const doneState = taskStates.find(
      (state) => state.task_state_name === "DONE"
    );
    const pendingState = taskStates.find(
      (state) => state.task_state_name === "PENDING"
    );

    if (onChecked) {
      if (pendingState) {
        if (pendingState.task_state_id) {
          onPut(pendingState.task_state_id);
        }
      }
    } else {
      if (doneState) {
        if (doneState.task_state_id) {
          onPut(doneState.task_state_id);
        }
      }
    }

    setOnChecked((prevChecked) => !prevChecked);
  };

  useEffect(() => {
    let myPromises = [
      GenericGetResource(`/taskstates`),
      GenericGetResource(`/projecttask/${task.project_task_id}`),
    ];

    Promise.all(myPromises)
      .then(async (response) => {
        setTaskStates(response[0].data.data);
        setProjectTask(response[1].data.data);
        const currentState = response[0].data.data.find(
          (state: TaskState) =>
            state.task_state_id === response[1].data.current_task_state_id
        );

        if (currentState && currentState.task_state_name === "DONE") {
          setOnChecked((prevChecked) => true);
        } else {
          setOnChecked((prevChecked) => false);
        }
        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!dataloaded && 
         <div className="w-100 my-2">
          <Spinner isBox={false} />
        </div>
      }
      {dataloaded && (
        <Grid item key={task.project_task_id} xs={12}>
          <Box
            className="create-button-task"
            sx={{
              borderRadius: "8px",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              justifyContent: "space-between",
              backgroundColor: color
                ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.2)`
                : "rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: color
                  ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.09)`
                  : "rgba(0, 0, 0, 0.1)",
              },
              display: "flex",
              alignItems: "center",
              padding: "8px",
            }}
            onClick={() => {
              setSelectedTaskId(task.project_task_id);
              setOpenDialogUpdateTask(true);
            }}
          >
            <FormControlLabel
              sx={{ marginLeft: 0.1 }}
              control={
                <Checkbox
                  checked={onChecked}
                  onChange={(e) => {
                    setOpenDialogUpdateTask(false);
                    handleTaskCompletion();
                  }}
                  icon={<CircleOutlined />}
                  checkedIcon={<CheckCircle />}
                  sx={{
                    color: "primary.main",
                    "&:hover": {
                      color: "primary.main",
                    },
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
              }
              label={
                <span
                  style={{
                    textDecoration: onChecked ? "line-through" : "none",
                    color: onChecked ? "gray" : "inherit",
                  }}
                >
                  {task.project_task_name}
                </span>
              }
              disabled={loadingPut}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};
