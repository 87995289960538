import React from "react";
import { Box, SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

interface CardStageProps {
  key?: React.Key | null | undefined,
  color: number[] | undefined;
  onClick: () => void;
  children: React.ReactNode;
}

const GetCardStageStyles = (color: number[] | undefined): SxProps<Theme> | undefined => ({
  padding: "10px 16px",
  borderRadius: 1,
  boxShadow: '0 2px 3px rgba(0, 0, 0, 0.3)',
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  transition: "background-color 0.3s ease, box-shadow 0.3s ease",
  backgroundColor: color
    ? `rgb(${color[0] - 20}, ${color[1] - 20}, ${color[2]}, 0.7)`
    : "rgba(0, 0, 0, 0.1)",
  backdropFilter: 'blur(5px)',
  color: '#ffffff',
  width: "100%",
});

export const CardStage = ({
  key,
  color,
  onClick,
  children,
}: CardStageProps) => {
  return (
    <Box key={key} sx={GetCardStageStyles(color)} onClick={onClick}>
      {children}
    </Box>
  );
};